<template>
  <div>
    <el-form label-position="left" label-width="8rem">
      <div class="y-desc" style="margin-bottom: 2rem;text-align: center">
        <a href="https://console.cloud.tencent.com/cam/capi" target="_blank">
          登录腾讯云后点此进入SecretID、SecretKEY获取页面
        </a>
      </div>
      <el-form-item label="SecretID">
        <el-input v-model="form.secret_id"></el-input>
      </el-form-item>
      <el-form-item label="SecretKEY">
        <el-input v-model="form.secret_key"></el-input>
      </el-form-item>
      <div class="y-desc" style="margin:2rem 0 1rem;text-align: center">
        <a href="https://console.cloud.tencent.com/cos/bucket" target="_blank">
          点此前往创建bucket,须确保bucket为 <strong>可公共读取</strong> 的，创建后将概览页面的 <strong>访问域名</strong> 填入下方 <strong>访问域名</strong>
        </a>
      </div>
      <el-form-item label="访问域名">
        <el-input v-model="form.default_url"></el-input>
        <div class="y-desc">此访问域名用于识别appid,bucket以及bucket所在区域等关键操作，若非更换bucket请勿轻易变更</div>
      </el-form-item>
      <el-form-item label="自定义域名">
        <el-input v-model="form.url"></el-input>
        <div class="y-desc">腾讯云支持用户自定义访问域名。注：url开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
        <div class="y-desc">自定义域名将直接影响远程附件访问，如对腾讯云储存自定义访问域名相关配置操作并不了解请勿轻易操作此项</div>
      </el-form-item>
      <el-form-item>
        <el-button @click="edit" type="primary">保存配置</el-button>
        <el-button @click="$emit('all-local-up')" type="primary">一键上传至远程附件</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "TxCos",
  data() {
    return {
      form: {
        secret_id: "",
        secret_key: "",
        url: "",
        default_url: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.attach.remoteTxCos().then(res => {
        this.form = res;
      })
    },
    edit(){
      this.$u.api.attach.remoteTxCosEdit(this.form).then(()=>{
        this.$emit("submit")
      })
    }
  }
}
</script>

<style scoped>

</style>