<template>
  <div>
    <el-form label-position="left" label-width="10rem">
      <el-form-item label="AccessKey">
        <el-input v-model="form.access_key"></el-input>
      </el-form-item>
      <el-form-item label="SecretKey">
        <el-input v-model="form.secret_key"></el-input>
      </el-form-item>
      <el-form-item label="Bucket">
        <el-input v-model="form.bucket"></el-input>
      </el-form-item>
      <el-form-item label="Url">
        <el-input v-model="form.url"></el-input>
        <div class="y-desc">七牛支持用户自定义访问域名。注：url开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
      </el-form-item>
      <el-form-item>
        <el-button @click="edit" type="primary">保存配置</el-button>
        <el-button @click="$emit('all-local-up')" type="primary">一键上传至远程附件</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Qiniu",
  data() {
    return {
      form: {
        access_key: "",
        secret_key: "",
        bucket: "",
        url: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.attach.remoteQiniu().then(res => {
        this.form = res;
      })
    },
    edit(){
      this.$u.api.attach.remoteQiniuEdit(this.form).then(()=>{
        this.load();
        this.$emit("submit");
      })
    }
  }
}
</script>

<style scoped>

</style>